import styled from 'styled-components';

export const YellowTitleH1 = styled.h1`
  color: var(--global-title-h1_color, #faff00);
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 45px;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 35px;
    text-align: left;
  }

  & + h2 {
    margin-top: -20px;
  }
`;

export const YellowTitle = styled.h2`
  color: var(--global-title-h2_color, #faff00);
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 45px;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 35px;
    text-align: left;
  }
`;

export const YellowTitleH3 = styled.h3`
  color: var(--global-title-h3_color, #faff00);
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 45px;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 35px;
    text-align: left;
  }
`;
